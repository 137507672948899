import React from "react";

const Footer = () => (
  <>
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          <strong>Thanks for Visitng my portfolio Site!</strong> 

       
        </p>
      </div>
    </footer>
  </>
);

export default Footer;
